import io from 'socket.io-client';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Select from 'react-select'
import NumericInput from 'react-numeric-input';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

//import SlmChart from './slmChart';
import TcaDataGrid from './tcaDataGrid';
import NewChart  from './newChart';

import './App.css'

const dev_mode = false

const SLM_INFO = [
  { value: 'Alt2h3W6U3c1oJtQYypZFD', label: 'Menlo Park - Belle Haven' },
  { value: 'APHeBV2QW%0%qrvga8rRtD', label: 'Palo Alto - Heritage Park' },
  { value: 'Avv+jP0Q0X8VABFSb6rxnD', label: 'Palo Alto - SIDBY' },
  { value: 'Cth2hV2y+9W9KJPiTwBZtD', label: 'Palo Alto - Green Acres' }
]
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.updateSLMID = this.updateSLMID.bind(this);
    this.switchMetric = this.switchMetric.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    this.changeT_win = this.changeT_win.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleresetModalClose = this.handleresetModalClose.bind(this);
    this.handleresetModalOpen = this.handleresetModalOpen.bind(this);
    this.sendResetMsg = this.sendResetMsg.bind(this)
    
    let initial_tw = (sessionStorage.getItem('time_win') === null) ? 60 : parseInt(sessionStorage.getItem('time_win'))
    let initial_dir = (sessionStorage.getItem('direction') === null) ? false : (sessionStorage.getItem('direction') === 'left')
    this.state = {
      // time_win: 70,
      // active_slm: SLM_INFO[0]['value'],
      // metricBool: { "LMIN": true, "LEQ": true, "LMAX": true },
      pause: false,
      post_data: new Array(initial_tw).fill(0),
      data: new Array(initial_tw).fill(0),
      direction_st: initial_dir,
      SLM_Options: SLM_INFO,
      TCASA: {},
      TCASAids: [],
      dcaLimit: 2,
      selectedTCAs:[],
      resetModalOpen: false,
      reset_status:"",
      resetPassword: ""
    }
  }

  handleresetModalClose = () =>{
    this.setState({
      resetModalOpen: false
    })
  }

  handleresetModalOpen = () =>{
    this.setState({
      resetModalOpen: true
    })
  }

  sendResetMsg = () =>{
    console.log("sending reset message,")
    this.socket.emit("message", { 'state':'resetApp','password': this.state.resetPassword })
  }
    
  handleUnload = () => {
    console.log("UNLOADING")
    this.socket.close("leave", { 'username': this.socket.id, 'room': sessionStorage.getItem("active_slm") })
    console.log("done")
  }

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.handleUnload();
    });
  };

  componentDidMount() {
    if (sessionStorage.getItem('direction') === null) sessionStorage.setItem("direction", 'right')
    if (sessionStorage.getItem('LEQ_GRAPH') === null) sessionStorage.setItem("LEQ_GRAPH", true)
    if (sessionStorage.getItem('LMIN_GRAPH') === null) sessionStorage.setItem("LMIN_GRAPH", true)
    if (sessionStorage.getItem('LMAX_GRAPH') === null) sessionStorage.setItem("LMAX_GRAPH", true)
    if (sessionStorage.getItem('active_slm') === null || sessionStorage.getItem('active_slm') === "undefined") sessionStorage.setItem("active_slm", SLM_INFO[0]['value'])
    if (sessionStorage.getItem('time_win') === null) sessionStorage.setItem("time_win", 60)
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    this.setState({
      data: new Array(parseInt(sessionStorage.getItem("time_win"))).fill(0),
    })

    var sensorEndpoint = "https://slm-viz-app.onrender.com"

    if (dev_mode){
       sensorEndpoint = "http://localhost:8000"
    }

    this.socket = io.connect(sensorEndpoint, {
      //reconnection: true,
      transports: ['websocket']
    });
    let socket = this.socket
    let label = sessionStorage.getItem('active_slm') === null ? SLM_INFO[0]['value'] : sessionStorage.getItem('active_slm')
      socket.on('connect', function () {
      socket.emit("join", { 'username': socket.id, 'room': label })
    });

      this.socket.on('responseMessage', message => {
          let post_data, data, TCASA, TCASAids, SLM_Options, newState = {};
          console.log(message)
      if (message.hasOwnProperty("reset_status")) {
        this.setState({
          "reset_status": message['reset_status']
        })
      }
      if (message.hasOwnProperty("cache")) {
        message = message['cache'].slice(-parseInt(sessionStorage.getItem("time_win")))
        message.sort((a, b) => parseFloat(a['time']) - parseFloat(b['time']))
        if(message.length < 1){
          console.log("OFFLINE SLM")
        }
        this.setState({
          post_data: message
        })

        return ;
      }
      if (message.hasOwnProperty("name")) {
        let met_data = this.state.data;
        let post_met_data = this.state.post_data
        let time_p = (this.state.post_data.length > 0 && this.state.post_data[this.state.post_data.length - 1] !== 0) ? this.state.post_data[this.state.post_data.length - 1]["time"] : (Date.now() - ((parseInt(sessionStorage.getItem("time_win")))*1000))
        let time_o = (this.state.post_data.length > 0 && this.state.post_data[this.state.post_data.length - 1] !== 0) ? message["time"] > time_p : Math.abs(message["time"] - time_p) <= ((parseInt(sessionStorage.getItem("time_win")))*1000)
        //console.log(time_p,this.state.post_data.length > 0,this.state.post_data[this.state.post_data.length - 1] !== 0,time_o)
        if (time_o && message["id"] === sessionStorage.getItem("active_slm")) {
          if (post_met_data.length >= parseInt(sessionStorage.getItem("time_win"))) {
            post_met_data.shift()
          }
          let nd = [...met_data, message]
          nd.sort((a, b) => parseFloat(a['time']) - parseFloat(b['time']))
          // this.setState({
        //     data: nd
        //   }, () => {
        //     if (!this.state.pause) {
        //       let toAdd = this.state.data.pop()
        //       let pnd = [...this.state.post_data, toAdd]
        //       pnd.sort((a, b) => parseFloat(a['time']) - parseFloat(b['time']))
        //       this.setState({ post_data: pnd })
        //     }
        //   })
          data = nd;
          if(!this.state.pause) {
            let toAdd = this.state.data.pop()
            let pnd = [...this.state.post_data, toAdd]
            pnd.sort((a, b) => parseFloat(a['time']) - parseFloat(b['time']));
            post_data = pnd;
          }
        }
      }
      if (message.hasOwnProperty("flight")) {
        if(this.state.dcaLimit >= message['dca']){
          let oTC = this.state.TCASA
        let oTCids = this.state.TCASAids
        if(oTCids.includes(message['id'])){

          if(message['dca'] < oTC[message['id']]['dca']){
            oTC[message['id']] = message
          }
        }else{
          oTC[message['id']] = message
          oTCids.push(message['id'])
        }
        //this.setState({ TCASA: oTC, TCASAids: oTCids })
          TCASA = oTC;
          TCASAids = oTCids;
        }

      }
      if (message.hasOwnProperty("SELECT")) {
        //this.setState({ SLM_Options: message['SELECT'] })
        SLM_Options = message['SELECT'];
      }
        if(post_data) newState["post_data"] = post_data;
        if(data) newState["data"] = data;
        if(TCASA) newState["TCASA"] = TCASA;
        if(TCASAids) newState["TCASAids"] = TCASAids;
        if(SLM_Options) newState["SLM_Options"] = SLM_Options;
        if(Object.keys(newState).length > 0) this.setState(newState);
    })
    this.setupBeforeUnloadListener();

  }

  handleVisibilityChange() {
    if(document.visibilityState === "hidden" && this.socket && this.socket.connected) this.socket.disconnect();
    if(document.visibilityState === "visible" && this.socket && !this.socket.connected) this.socket.connect();
  }


  componentWillUnmount() {
    let act_room = (sessionStorage.getItem('time_win') === null) ? SLM_INFO[0]['value'] : sessionStorage.getItem("active_slm")
    this.socket.emit("leave", { 'username': this.socket.id, 'room': act_room })
    this.socket.close()
      //window.removeEventListener('beforeunload', this.handleUnload);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }


  selectTCA = (inp) =>{
    this.setState({selectedTCAs:inp})
  }

  updateSLMID(e) {
    console.log("Changing SLM")
    this.socket.emit("leave", { 'username': this.socket.id, 'room': sessionStorage.getItem("active_slm") })
    
    let newslm = e['serial_no'];
    console.log(newslm)
    this.socket.emit("join", { 'username': this.socket.id, 'room': newslm })
    sessionStorage.setItem("active_slm", newslm)
    this.setState({
      data: new Array(parseInt(sessionStorage.getItem("time_win"))).fill(0),
      post_data: new Array(parseInt(sessionStorage.getItem("time_win"))).fill(0),
      TCASA: {},
      TCASAids:[]
    })
  }

  switchMetric(e) {
    let key = e.target.innerText + "_GRAPH"
    let omb = sessionStorage.getItem(key) === 'true' ? 'false' : 'true'
    console.log("setting ", key, " to ", omb)
    sessionStorage.setItem(key, omb)
  }

  changeT_win(e, valE) {
    let val = valE * 60
    sessionStorage.setItem("time_win", parseInt(val))
    let currentData = this.state.post_data;
    if (val < currentData.length) {
      this.setState({
        post_data: currentData.slice(Math.max(currentData.length - val, 0))
      })
    } else {
      for (let i = 0; i < (val - currentData.length); i++) {
        currentData.unshift(0)
      }
      // this.setState({
      //   post_data: currentData
      // })
      this.socket.emit("leave", { 'username': this.socket.id, 'room': sessionStorage.getItem("active_slm") })
      let act_room = (sessionStorage.getItem('time_win') === null) ? SLM_INFO[0]['value'] : sessionStorage.getItem("active_slm")
      console.log("Rejoining",act_room)
      this.socket.emit("join", { 'username': this.socket.id, 'room': act_room })
    }
  }

  valuetext(value) {
    return `${value} sec`;
  }

  returnInfoKey() {
    let label = sessionStorage.getItem('active_slm') === null ? SLM_INFO[0]['value'] : sessionStorage.getItem('active_slm')
    let idx = 0
    for (idx; idx < SLM_INFO.length; idx++) {
      if (SLM_INFO[idx]['value'] === label) {
        return SLM_INFO[idx]
      }
    }
  }

  skipToLive = (e) => {
    let datatoPush = this.state.data.filter(w => w !== 0)
    let tw = (sessionStorage.getItem('time_win') === null) ? 60 : parseInt(sessionStorage.getItem('time_win'))
    this.setState({
      post_data: this.state.post_data.concat(datatoPush).slice(-tw),
      data: new Array(parseInt(sessionStorage.getItem("time_win"))).fill(0)
    })
  }

  pauseData = (e) => {
    this.setState({
      pause: !this.state.pause
    })
  }



  changeDirection = (e) => {
    let dir = e.target.checked ? 'left' : 'right'
    console.log(dir, e.target.checked)
    sessionStorage.setItem("direction", dir)
    this.setState({
      direction_st: e.target.checked
    })
  }

  reverseArr(input) {
    var ret = [];
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  formatForNivo = (data) =>{
    let res = [
      {
        "id": "Lmax",
        "color": "hsl(338, 47.1%, 52.5%)",
        "data": []
        },
      {
      "id": "LEq",
      "color": "hsl(243, 51.9%, 68.2%)",
      "data": []
      },
      {
        "id": "Lmin",
        "color": "hsl(143, 40.4%, 65.1%)",
        "data": []
        },

    ]
     // The 0 there is the key, which sets the date to the epoch
    let l2r = sessionStorage.getItem("direction") === null || sessionStorage.getItem("direction") === 'right' ? true : false
    for(let i =0; i<data.length; i++){
      if(data[i] !== 0){
        let d = new Date(data[i]['time']);
        // d.setSeconds(/1000)
        //d.toLocaleString("en-US", {timeZone: "America/Los_Angeles"})

    //   let usaTime = d.toLocaleString("en-US", {
    //     timeZone: "America/Los_Angeles"
    // });
    //     console.log(data[i]['name'], typeof(d), typeof(usaTime))
        sessionStorage.getItem("LMIN_GRAPH") === 'true' && res[2]['data'].push({x:d,y:data[i]['lmin'],time:data[i]['time']})
        sessionStorage.getItem("LEQ_GRAPH") === 'true' && res[1]['data'].push({x:d,y:data[i]['leq'],time:data[i]['time']})
        sessionStorage.getItem("LMAX_GRAPH") === 'true' && res[0]['data'].push({x:d,y:data[i]['lmax'],time:data[i]['time']})
      }
      // else{
      //   let d = new Date();
      //   d.setSeconds(d.getSeconds - 60 + i)
      //   //
      //   let np = parseInt(Math.floor(Math.random() * (80 - 35 + 1) + 35))
      //   sessionStorage.getItem("LMIN_GRAPH") === 'true' && res[2]['data'].push({x:d,y:np})

      // }
    }
    if(l2r){
      this.reverseArr(res[0]['data'])
    }
    return res
  }

  changeDCALimit = (v) =>{
    this.setState({dcaLimit: v})
    this.socket.emit('message',{state:"change_dca_value",value:v*1609.34})
  }

  render() {
    return (
      <>
      <Grid item xs={12}>
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ fontWeight: 200 }}>Real Time SLM DATA</h1>
          
          <Grid container direction="row"
          justifyContent="center"
          alignItems="center" spacing={1}>
          <Grid item xs={2}>
              <Select options={this.state.SLM_Options} onChange={this.updateSLMID} defaultValue={this.returnInfoKey()} />
          </Grid>
          <Grid>
            <Button onClick={this.handleresetModalOpen} style={{'color': '#c26b6b'}}variant="outlined" color="secondary">Reset app</Button>
          </Grid>
          </Grid>
          
        </div>
        {this.state.post_data.length <=0 &&
        <p style={{textAlign:'center'}}>This SLM is offline, please choose another device</p>}
        <Grid container direction="row"
          justifyContent="center"
          alignItems="center" spacing={2}>

          <Grid item xs={12} style={{ width: '80%', height: '61vh', marginBottom: '5vh' }}>
            <Paper style={{
              marginLeft: '15px',
              paddingRight: '10px',
              paddingTop: '45px',
              width: '98%',
              height: '100%',
            }}>
              {this.state.post_data[this.state.post_data.length-1] !== 0 && this.state.post_data.length >0 &&
              <NewChart selectedTCAs={this.state.selectedTCAs} data={this.formatForNivo(this.state.post_data)} tcaData={Object.keys(this.state.TCASA).map(e=>this.state.TCASA[e])} tcaIDS={this.state.TCASAids} dcaLim={this.state.dcaLimit}/>
              }
              {/* <SlmChart data={this.state.post_data} tcaData={Object.keys(this.state.TCASA).map(e=>this.state.TCASA[e])} tcaIDS={this.state.TCASAids} /> */}


            </Paper>
          </Grid>
          <Grid item >
          <IconButton onClick={this.pauseData} color="primary">
                {this.state.pause ? <PlayArrowIcon /> : <PauseIcon />}
              </IconButton>
              <IconButton onClick={this.skipToLive} color="primary">
                <SkipNextIcon />
              </IconButton>
            </Grid>
            <Grid item >
              <ButtonGroup color="primary" id={"aa"} aria-label="outlined primary button group">
                <Button onClick={this.switchMetric} variant={sessionStorage.getItem("LMIN_GRAPH") === 'true' ? "contained" : "outlined"}>Lmin</Button>
                <Button onClick={this.switchMetric} variant={sessionStorage.getItem("LEQ_GRAPH") === 'true' ? "contained" : "outlined"}>Leq</Button>
                <Button onClick={this.switchMetric} variant={sessionStorage.getItem("LMAX_GRAPH") === 'true' ? "contained" : "outlined"}>Lmax</Button>
              </ButtonGroup>
            </Grid>
          <Grid item xs={2}>
          <Slider
                defaultValue={sessionStorage.getItem("time_win") === null ? 1 : parseInt(sessionStorage.getItem("time_win"))/60}
                getAriaValueText={this.valuetext}
                aria-labelledby="discrete-slider"
                onChange={this.changeT_win}
                step={1}
                marks
                min={1}
                max={60}
                valueLabelDisplay="auto"
              /></Grid>
<Grid item xs={2}>DCA Limit (mi) <NumericInput className={"dcaLimitInput"} min={0} max={8} value={this.state.dcaLimit} step={0.5} onChange={this.changeDCALimit}/></Grid>



              {/* <Grid item xs={1}>
               Left to Right: <Switch
                checked={this.state.direction_st}
                onChange={this.changeDirection}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
          </Grid> */}


            <div style={{ textAlign: 'center' }}>
              {/* <input type="number" min={1} max={100} value={sessionStorage.getItem("time_win")} onChange={this.changeT_win}/> */}



              <br />

              <br /><br />

            </div>

          <Grid item style={{ width: '80%' }}>
            <TcaDataGrid data={Object.keys(this.state.TCASA).map(e=>this.state.TCASA[e])} tcaSelector={this.selectTCA}/>
          </Grid>

        </Grid>
          
      </Grid>
      <Modal
      open={this.state.resetModalOpen}
      onClose={this.handleresetModalClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={{position: 'absolute',
    width: 400,
    backgroundColor: 'white',
    top: '40%',
    left: '35%',
    border: '2px solid #000',
    boxShadow: 5,
    padding: 5,
   }}>
    <h2 style={{textAlign:'center'}}id="simple-modal-title">Reset App</h2>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing='2'
    >
      {/* <Grid item>
      <p id="simple-modal-description">
      Enter password
    </p>
    
      </Grid> */}
      <Grid item>
      <TextField
          id="standard-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          value={this.state.resetPassword}
          onChange={event => this.setState({resetPassword: event.target.value})}
    //       =(event) => {
    // setName(event.target.value);
        />
        
          
      </Grid>
      <Grid item>
      <Button onClick={this.sendResetMsg} style={{'color': '#c26b6b'}}variant="outlined" color="secondary">Reset app</Button>
      </Grid>
      <Grid item>
      <p>{this.state.reset_status}</p>
      </Grid>
    </Grid>
    
  </div>
    </Modal>
      </>
    )
  }

}
