import { ResponsiveLine, ResponsiveLineCanvas } from '@nivo/line'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
import FileSaver from "file-saver";
import React, { useCallback, useState } from "react";
import { useCurrentPng } from "recharts-to-png";
import Button from '@material-ui/core/Button';
import {saveSvgAsPng} from "save-svg-as-png"
import { TrendingUpRounded } from '@material-ui/icons';

function NewChart(props) {
    // useCurrentPng usage (isLoading is optional)
  const [getPng, { ref, isLoading }] = useCurrentPng();

  // Can also pass in options for html2canvas
  // const [getPng, { ref }] = useCurrentPng({ backgroundColor: '#000' });

  const handleDownload = useCallback(async () => {
    const png = await getPng();
    console.log(png)
    saveSvgAsPng(document.getElementsByTagName("svg")[1], "diagram.png",{backgroundColor:"white"});
    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, 'slm_data.png');
    }
  }, [getPng]);

  const tcaLineFill = (dca,color) =>{
      let div = 1-(dca/props.dcaLim)
      return `rgba(${color}, ${div})`
  }

  const findIdx = (data)=>{
      if(data[0]['data'].length>0){
          return 0
      }else if(data[1]['data'].length>0){
        return 1
    }else{
        return 2
    }
  }
  const xTicks = props.data[findIdx(props.data)]['data'].length > 180 ? '2 minutes' : '10 seconds'
    //console.log(props.data)
    return (
        <>
        <ResponsiveLine

            data={props.data}

            margin={{ top: 20, right: 40, bottom: 100, left: 50 }}
            xScale={{
                type: "time",
                format: "%H:%M:%S"
            }}
            xFormat="time:%H:%M:%S"
            yScale={{ type: 'linear', min: '30', max: '85', stacked: false, reverse: false }}
            // yFormat=" >-.2f"
            curve="cardinal"
            axisTop={null}
            axisRight={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: 'db-A',
                // legendOffset: 40,
                // legendPosition: 'middle'
            }}

            axisBottom={{
                // orient: 'bottom',
                format: "%H:%M:%S",
                tickValues: `every ${xTicks}`,
                // tickValues: 10,
                // tickSize: 5,
                // tickPadding: 5,
                // tickRotation: 0,
                legend: '',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'db-A',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            enableGridX={false}
            //enableGridY={true}
            enablePoints={false}
            pointSize={10}
            // pointColor={{ theme: 'background' }}
            // pointBorderWidth={2}
            // pointBorderColor={{ from: 'serieColor' }}
            // pointLabelYOffset={-12}
            enableSlices={false}
                useMesh={true}
            colors={{ scheme: 'pastel1' }}
            lineWidth={2}
            tooltip={point => {

                return <div style={{background:'#f2e5c2',padding:5}}>
                    {point.point.data.xFormatted} <br/>
                    {point.point.serieId}: {point.point.data.y} <br/>
                        </div>;
              }}
            markers={props.tcaData.map(ev => {
                let d = new Date(ev['tcaSA_ems']*1000)
                //d.setSeconds(ev['tcaSA_ems']*1000)
                //console.log(d,d.toString())
                //console.log(props.data)
                if((props.data[findIdx(props.data)]['data'].length)>0){

                    if(ev['tcaSA_ems']*1000>props.data[findIdx(props.data)]['data'][0]['time']){
                        if(props.selectedTCAs.length>0){
                            if(props.selectedTCAs.includes(ev['icao'])){
                                return {
                                    axis: 'x',
                                    value: d,
                                    legend: <><tspan x="0" dy="1.2em">{ev['flight']}</tspan>
                                    <tspan x="0" dy="1.2em">{ev['icao']}</tspan>
                                    <tspan x="0" dy="1.2em">{ev['dca']} mi</tspan></>,
                                    //legendOrientation: 'vertical',
                                    legendPosition: 'bottom',
                                    lineStyle: {
                                        stroke: tcaLineFill(parseFloat(0),'255, 165, 0'),
                                        strokeWidth: '3px'
                                    },
                                    textStyle: {
                                        fill: '#7C7CBD',
                                        fontSize: '10px',
                                        fontWeight:500,
                                        transform: "translate(-29px, 2px) rotate(-90deg)!important"

                                    },
                                    //textTransform
                                }
                            }

                        }else{
                        return {
                            axis: 'x',
                            value: d,
                            legend: <><tspan x="0" dy="1.2em">{ev['flight']}</tspan>
                            <tspan x="0" dy="1.2em">{ev['icao']}</tspan>
                            <tspan x="0" dy="1.2em">{ev['dca']} mi</tspan></>,
                            //legendOrientation: 'vertical',
                            legendPosition: 'bottom',
                            lineStyle: {
                                stroke: tcaLineFill(parseFloat(ev['dca']),'255, 165, 0'),
                                strokeWidth: '3px'
                            },
                            textStyle: {
                                fill: '#7C7CBD',
                                fontSize: '10px',
                                fontWeight:500,
                                transform: "translate(-29px, 2px) rotate(-90deg)!important"

                            },
                            //textTransform
                        }
                    }
                    }else{
                        return {}
                    }
                }
                return {}

            })}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 18,
                    translateY: 86,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            animate={false}
        />
        <br />
      <Button style={{float:'right'}} variant={"outlined"} onClick={handleDownload}>
        {isLoading ? 'Downloading...' : 'Download Chart'}
      </Button>
      </>
    )
}

export default NewChart;
