import * as React from 'react';
import { DataGrid,GridToolbarContainer,
    GridToolbarExport, } from '@material-ui/data-grid';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
        <GridToolbarExport />
        </GridToolbarContainer>
    );
}
const columns = [
    {
        field: 'flight',
        headerName: 'Flight',
        width: 120,
        sortable: false,
        renderCell: (params) => (
        <a href={"https://flightaware.com/live/flight/"+params.value} target="_blank" rel="noreferrer">{params.value}</a>),
    },
    { field: 'icao', headerName: 'ICAO', width: 120 },

    {
        field: 'tcaSA',
        headerName: 'TCA-SA',
        width: 130,
        sortComparator: (v1, v2, param1, param2) =>
      param1.api.getCellValue(param1.id, 'tcaSA_ems') -
      param2.api.getCellValue(param2.id, 'tcaSA_ems'),
    },
    {
        field: 'dca',
        headerName: 'DCA (mi)',
        headerAlign: true,
        align: 'left',
        disableColumnMenu: true,
        hideSortIcons: true,
        type: 'number',
        width: 100,
    },
    {
        field: 'altitude',
        headerName: 'Altitude (ft)',
        type: 'number',
        align: 'left',
        headerAlign: true,
        disableColumnMenu: true,
        hideSortIcons: true,
        width: 125,
    },
    {
        field: 'pcaLat',
        headerName: 'PCA Lat',
        sortable: false,
        valueGetter: (params) =>
      `${params.getValue(params.id, 'pca')[0] || ''}`,
        width: 120,
    },
    {
        field: 'pcaLon',
        headerName: 'PCA Lon',
        sortable: false,
        valueGetter: (params) =>
      `${params.getValue(params.id, 'pca')[1] || ''}`,
        width: 120,
    },
];


export default function TcaDataGrid(props) {
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'tcaSA',
          sort: 'desc',
        },
      ]);

      const [selectionModel, setSelectionModel] = React.useState([]);

    

    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={props.data}
                columns={columns}
                pageSize={13}
                checkboxSelection={true}
                sortModel={sortModel}
                onRowClick={(e)=>{
                    console.log("clicked",e)
                }}
                onSelectionModelChange={(newSelectionModel) => {
                    console.log(newSelectionModel)
                    props.tcaSelector(newSelectionModel)
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
        onSortModelChange={(model) => setSortModel(model)}
        components={{
            Toolbar: CustomToolbar,
          }}
            />
        </div>
    );
}